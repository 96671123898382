// ========================
// Nord Colors and Palletes
// ========================
//
// https://www.nordtheme.com/docs/colors-and-palettes
//
// Please consult the documentation above when
// determining the applications of the nord colors.
//
// ========================

@import 'node_modules/nord/src/sass/nord.scss';

$primary: $nord10;
$secondary: $nord9;
$success: $nord14;
$info: $nord8;
$warning: $nord13;
$danger: $nord11;
$light: $nord6;
$dark: $nord0;

$gray-100: $nord6;
$gray-200: $nord5;
$gray-300: $nord4;
$gray-600: $nord3;
$gray-700: $nord2;
$gray-800: $nord1;
$gray-900: $nord0;

$card-cap-bg: $light;
$card-border-color: $gray-100;
$card-border-width: 2px;

@import 'node_modules/bootstrap/scss/bootstrap';

html,
body,
#app {
  height: 100%;
}

.btn-primary {
  color: $gray-100;

  &:disabled,
  &:hover {
    color: $gray-200;
  }
}

@mixin fullpage-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login,
.forgot-password,
.reset-password {
  @include fullpage-center;

  .card {
    width: 20rem;
  }
}

.nav {
  &.root {
    .nav-item {
      @include media-breakpoint-down('md') {
        padding: 0.5rem 0rem;
      }

      svg {
        @include media-breakpoint-up('md') {
          margin-right: 0.5rem;
        }
      }
    }
  }

  & + .container-fluid,
  & + .container {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
}

.row .card {
  margin-bottom: 2rem;
}

.form-group {
  text-align: left;
}

.card {
  .card-header {
    height: 3.5rem;
    line-height: 2.5rem;
  }
  .list-group-item-text:last-child {
    margin-bottom: 0rem;
  }
}
